import { createActionGroup, props } from '@ngrx/store';
import { LanguagesEntity } from './languages.reducer';

const Language = createActionGroup({
  source: 'Languages',
  events: {
    initData: props<{ languages: LanguagesEntity[] }>(),
    changeLanguage: props<{ selectedId: string }>(),
    changeLanguageSuccess: props<{ selectedId: string }>()
  }
});

export const LanguageActions = {
  ...Language
};
