import { LangDefinition } from '@jsverse/transloco';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { LanguageActions } from './languages.actions';

export interface LanguagesEntity extends LangDefinition {}

export interface LanguagesState extends EntityState<LanguagesEntity> {
  selectedId: string | null;
}

export const languagesAdapter: EntityAdapter<LanguagesEntity> = createEntityAdapter<LanguagesEntity>({
  selectId: (languages: LanguagesEntity) => languages.id
});

export const initialState: LanguagesState = languagesAdapter.getInitialState({
  selectedId: ''
});

export const languageFeature = createFeature({
  name: 'languageState',
  reducer: createReducer(
    initialState,
    on(LanguageActions.initData, (state, { languages }) => languagesAdapter.setAll(languages, { ...state, loaded: true })),
    on(LanguageActions.changeLanguageSuccess, (state, { selectedId }) => ({ ...state, selectedId }))
  ),
  extraSelectors: ({ selectLanguageStateState }) => ({
    selectAllLanguages: languagesAdapter.getSelectors(selectLanguageStateState).selectAll
  })
});

const { name, reducer, ...selectors } = languageFeature;
export const fromLanguage = selectors;
